import { sentryClient } from '@/utils/sentryClient';
import { sessionStorageEnabled } from '@/utils/checkSessionStorage';

const getParsedIds = (item) => {
    let { campaignId, variationId } = item;

    if (typeof campaignId === 'string') {
        campaignId = parseInt(campaignId, 10);
    }

    if (typeof variationId === 'string') {
        variationId = parseInt(variationId, 10);
    }

    return {
        campaignId,
        variationId,
    };
};

const getAbTestData = () => {
    if (!sessionStorageEnabled()) {
        return undefined;
    }

    const session = sessionStorage.getItem('ab_test_session');
    if (!session) {
        return undefined;
    }

    const campaignIds = [];
    const variationIds = [];

    try {
        const data = JSON.parse(session);
        data.forEach((item) => {
            const { campaignId, variationId } = getParsedIds(item);
            if (typeof campaignId === 'number' && typeof variationId === 'number') {
                campaignIds.push(campaignId);
                variationIds.push(variationId);
            }
        });
    } catch (e) {
        sentryClient.captureException(e);
    }

    if (campaignIds.length > 0 && variationIds.length > 0) {
        return {
            vwo: {
                campaign_ids: campaignIds,
                variation_ids: variationIds,
            },
        };
    }

    return undefined;
};

export default getAbTestData;
