import getAbTestData from '@/utils/getAbTestData';

/**
 * Used to format data for the backend post.
 * @param data: {object}
 * @param trackinginfo: {object}
 * @returns {object}
 */
const prepareDataForBackendPost = (data, trackinginfo) => ({
    zipcode: data.collectedData.customer.zipcode,
    referrer_id: data.settings.referrerId.value,
    customer: {
        salutation: data.collectedData.customer.salutation,
        first_name: data.collectedData.customer.first_name,
        last_name: data.collectedData.customer.last_name,
        phone: data.collectedData.customer.phone,
        email: data.collectedData.customer.email,
        zipcode: data.collectedData.customer.zipcode,
        terms_contact_accepted: data.collectedData.customer.terms_contact,
        terms_contact_accepted_text: data.collectedData.customer.terms_contact_text,
        terms_privacy_accepted: data.collectedData.customer.terms_privacy,
        terms_privacy_accepted_text: data.collectedData.customer.terms_privacy_text,
    },
    trackinginfo: {
        msclkid: trackinginfo.msclkid,
        page_landing_last_visit: trackinginfo.thermondo__page_landing__last_visit,
        page_referrer_last_visit: trackinginfo.thermondo__page_referrer__last_visit,
        medium_last_visit: data.settings.referrerId.value ? 'kwk' : trackinginfo.thermondo__medium__last_visit,
        source_last_visit: trackinginfo.thermondo__source__last_visit,
        gclid: trackinginfo.google_analytics__gclid,
        campaign_last_visit: trackinginfo.thermondo__campaign__last_visit,
        // eslint-disable-next-line no-underscore-dangle
        ga: trackinginfo._ga,
        ab_test_data: getAbTestData(),
    },
    leadformrequest: data.collectedData.answers,
});

const hash = async (string) => {
    const utf8 = new TextEncoder().encode(string);
    return crypto.subtle.digest('SHA-256', utf8).then((hashBuffer) => {
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray
            .map(bytes => bytes.toString(16).padStart(2, '0'))
            .join('');
        return hashHex;
    });
};

export { prepareDataForBackendPost, hash };
